import { baseUrl, aiApiUrl,aiLiveUrl,baseApiVersion, headers,aiBaseUrl } from "../config/env";
import axios from "axios";
baseUrl, baseApiVersion
require("vue-i18n");


export function fetchAiVodResult(params){
    params
    return axios.get("http://172.19.200.52:5000/ai/results", {
        params,
        headers,
    });
}

export function fetchLastResult (params){
    return axios.get(aiBaseUrl+aiApiUrl + `/last_result`, {
        params,
        headers,
      });
}

export function fetchResults (params){
  return axios.get(aiBaseUrl+aiApiUrl + `/results`, {
      params,
      headers,
    });
}

export function fetchLastResultImage(params){
    return axios.get(aiBaseUrl +aiLiveUrl+ `/last_image`, {
        params,
        headers,
        responseType: 'blob'
      });
}

